html,
body,
#root {
  height: 100%;
}

body {
  margin: 0;
}

.ant-input-number-handler-wrap {
  display: none;
}

.ant-form-item-label label {
  white-space: normal !important;
  height: auto !important;
  width: 157px;
  text-align: start;
}

.schema-name-heading {
  text-align: center;
  text-transform: capitalize;
  padding: 2px 0;
  font-size: 18px;
}

.ant-pagination-item {
  display: none !important;
}

@media (max-width: 575px) {
  .ant-form-item.overwriteCheckbox {
    float: none;
  }
}
