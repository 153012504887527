.formInNumber {
    width: 100%;
}

.divederHeight {
    height: 100%;
}

.overwriteCheckbox {
    float: left;
    
  }

.editForm {
    margin-right: 74px !important;
}

.addForm {
    margin-right: 64px !important;
}
