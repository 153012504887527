.center {
  margin: auto;
  width:50%;
  max-width: 700px;
  padding: 10px;
  text-align: left;
}

.content {
  padding-top: 30px;
}

.user_info_container {
  margin-left: 143px;
}

@media (min-width: 2050px) {
  .user_info_container {
    margin-left: 170px;
  }
}
