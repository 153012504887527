
.normal {
  font-family: Georgia, sans-serif;
  text-align: center;
}

.title {
  font-size: 2.5rem;
  font-weight: normal;
  letter-spacing: -1px;
  background: darkslateblue;
  padding: .6em 0;
  color: white;
  margin: 0;
}

.logo {
  height: 128px;
  background: url(../assets/FinIcon.png) no-repeat center 0;
  background-size: 128px 128px;
  margin: 30px;
}

.header_title {
  color: #e683ff;
  margin-top: 6px;
}
