.transactionBtn {
  border: 0;
  background: transparent;
}
.transactionDisable {
  opacity: 0.4;
  position: relative;
  background: transparent;
}
.transactionDisableOverlay {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1000;
}
