.header_title {
  color: #e683ff;
  margin-top: 6px;
}

.label {
  color: #000000;
  margin-right: 10px;
}

.bg_primary {
  background-color: #e683ff;
}

.bg_gray {
  background-color: gray;
}

.app_header {
  padding: 0;
  height: auto;
  line-height: unset;
}

.mb_10{
  margin-bottom: 10px;
}

.user_info {
  margin-bottom: 23px;
}

.user_info_header {
  margin: 3px 0 15px 0;
}
.add_payee_btn_bucket {
  border-top: 1px solid #f0f0f0;
  display: flex;
  justify-content: flex-end;
  padding-top: 10px;
}

.add_payee_btn_bucket button:last-child {
  margin-left: 8px;
}

.clipBoardBtn {
  background-color: transparent;
  border: 0;
  border-bottom: 1px solid grey;
  padding: 0;
  cursor: pointer;
}

