.center {
  margin: auto;
  width:50%;
  max-width: 700px;
  padding: 10px;
}

.content {
  padding-top: 30px;
}
